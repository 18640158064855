import React, { Component } from "react";
import { membership, ctbidspremier, membershipicon, distribution, PremierAFA, PremierWorthPoint, PremierService, PremierDelivery, sneakPeekBerry,premierServiceBerry,premierAdvisorBerry } from "shared/images/images";
import './vipmembership.scss'
import { Button } from "react-bootstrap";
import store from "features/store";
import { isAuthorized } from "shared/settings/auth";
import { connect } from "react-redux";
import { addDataLayer, bannerImage, pmAutoNavigateToNextScreen } from "shared/utils/utils";
import { Helmet } from "react-helmet";
import pmservice from "shared/services/pmservice";
import Footer from "../../layouts/Footer";

class VipMembership extends Component {
    constructor(props) {
        super(props);
        this.state = {
            membershipData: {},
            pageLoading: true
        }
    }
    joinTodayfn = () => {
        if (isAuthorized()) {
            if (!this.props.user.membershipCompleted) {
                this.props.history.push('/my-account/join-premier-member');
            } else {
                this.props.history.push('/');
            }
        }
        else {
            store.dispatch({ type: 'TOGGLE_SIGNIN_MODAL', payload: { open: true, mode: 'Sign-In' } });
        }
    }

    componentDidMount = () => {
        if (this.props.user.membershipCompleted) {
            this.props.history.push('/');
        }
        this.getPremierMemebrship();
    }

    getPremierMemebrship = () => {
        pmservice.getContent('premiermembership', '').then((response) => {
            let data = JSON.parse(response.data.data[0].jsoncontent);
            this.setState({
                membershipData: data,
                pageLoading: false
            });
        }).catch({
            pageLoading: false
        });
    }

    componentDidUpdate = (prevProps) => {
        if (JSON.stringify(prevProps.user) !== JSON.stringify(this.props.user) && this.props.user.membershipCompleted == 1) {
            this.props.history.push('/');
        }
    }
    handleNavigation = (path) => {
        this.props.history.push({ pathname: path });
    };
    textColorChange = (data) => {
        if (data.includes('#')) {
            let contentData = data.split('#');
            return (
                <>
                    {contentData[0] && <div style={{ color: '#A50050', fontSize: "20px" }}>{contentData[0]}</div>}
                    {contentData[1] && contentData[1]}
                </>
            );
        } else {
            return data;
        }
    };


    linkAddFn = (data) => {
        const parts = data.split(/SPLIT#/);

    return parts.map((part, index) => {
        if (part.includes('#')) {
            const [linkText, externalPath] = part.split('#');
            if (linkText.includes('CTBids Premier Terms')) {
                return (
                    <a key={index} className="disclaimer-link" href={externalPath} target="_blank" rel="noopener noreferrer">
                        {linkText}
                    </a>
                );
            } else {
                return (
                    <span key={index} className="disclaimer-link" onClick={() => this.handleNavigation(linkText)}>
                        {externalPath}
                    </span>
                );
            }
        }

        return <span key={index}>{part}</span>;
    });
    };

    changeToLink = (data) => {
        if (data) {
            if (data.includes('Click here to join today!')) {
                const parts = data.split('Click here to join today!');
                return (
                    <>
                        {parts[0]}
                        <a className="link-color clickable" onClick={() => { this.navigateToPM(this.props) }}>Click here to join today!</a>
                        {parts[1]}
                    </>
                );
            }
            return data;
        }
        return null;
    }
    boldTextChange = (data) => {
        if (data.includes('#bold')) {
            let contentData = data.split('#');
            let span = contentData[3] ? <span style={{ color: `#${contentData[2]}`, fontWeight: contentData[1]}}>{contentData[3]}</span> : <></>;
            return (
                <>
                    {contentData[0] && <div>{contentData[0]}{span}</div>}
                </>
            );
        } else {
            return data;
        }

    }

    navigateToPM = (props) => {
        if (props.user.id) {
            pmAutoNavigateToNextScreen(props, props.user, null, null, null, false, true);
            let dataLayerData = {
                'event': 'join_premier',
                'title': 'Premier-TextLink-PMMainOfferPage-Clickhere1'
            }
            addDataLayer(dataLayerData);
        } else {
            store.dispatch({ type: 'TOGGLE_SIGNIN_MODAL', payload: { open: true, mode: 'Sign-In', fromBannerBtn: true } });
        }
    }

    render() {
        const { membershipData } = this.state;
        const benefits = membershipData.body ? membershipData.body[0].benefitData : [];

        const renderBenefits = (benefits) => {
            let rows = [];
            for (let i = 0; i < benefits.length; i += 3) {
                rows.push(
                    <div className="mt-5 memerbership-serivce" key={i}>
                        {benefits.slice(i, i + 3).map((benefit, index) => (
                            <div key={index} className='membership-service-content'>
                                <img src={benefit.logo} className="membership-icons" alt="" />
                                <div className="membership-service">
                                    <div className="service-title fs-20">{benefit.title}</div>
                                    <div>{this.boldTextChange(benefit.description)}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                );
            }
            return rows;
        }

        return (
            <div className="h-100" style={{ overflow: 'auto' }}>
                <Helmet>
                    <title>CTBids | Premier Membership</title>
                </Helmet>
                {this.state.pageLoading ?
                    <div className="h-100 d-flex align-items-center justify-content-center">
                        <img src={`${process.env.PUBLIC_URL}/static/icons/table/loader.gif`} className="loader-icon" alt="loader-icon"></img>
                    </div> :
                    <div className="vip-membership">
                        <div className="premier-banner">
                            <img src={`${membership}`} className="membership-header" />
                            <div className="membership-title">
                                {/* Start Bidding with a <i>Premier Experience</i> */}
                            </div>
                        </div>
                        <div className="membership-details" style={{ color: '#00263E' }}>
                            {membershipData.body && membershipData.body.map((section, index) => (
                                <div key={index} className="mt-5 membership-logo-content" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div className="mr-3">
                                        <img src={section.membershiplog} alt="" className="membership-logo" />
                                    </div>
                                    <div style={{ width: '5px', height: '100px', backgroundColor: '#00263E' }}></div>
                                    <div className="ml-3">
                                        <img src={section.shieldlogo} alt="" className="membership-service-img" />
                                    </div>
                                </div>
                            ))}
                            <div className="mt-3 fs-20" style={{ padding: '20px' }}>
                                {membershipData.body && <div>{this.changeToLink(membershipData.body[0].welcomemsg)}
                                </div>}
                            </div>
                            <div className="mt-3 sub-menu fs-20" style={{ textAlign: 'center', padding: '20px' }}>
                                <h1 style={{ fontWeight: 'bold' }}>{membershipData?.body && membershipData?.body[0].description}</h1>
                                {renderBenefits(benefits)}
                            </div>
                            {!this.props.user.membershipCompleted ?
                                <div className='w-100' style={{ justifyContent: 'center', alignItems: ' center', display: 'flex', }}>
                                    {bannerImage(this.props, 'Premier-BannerAd-PMMainOfferPage-Unlock3')}
                                </div> : ''}

                            <div className="footer-content mt-4">
                                {membershipData.footer &&
                                    <>
                                        <div className="img-section">
                                            <img src={membershipData.footer[2]?.logo} alt="" className="footer-img" />
                                        </div>
                                        <div className="ml-5">
                                            <h2 className="text-bold" style={{ fontSize: '20px', fontWeight: 'bold' }}>{membershipData.footer[2]?.title}</h2>
                                            <div>{membershipData.footer[2]?.subtitle}</div>
                                        </div>
                                    </>
                                }
                            </div >
                            <div style={{ display: 'flex', justifyContent: 'center'}}>
                            <div className="mt-5  font-weight-bold" style={{ backgroundColor: '#00263E', height: '3px', width: '90%', padding: '0% 10%' }}></div>

                            </div>
                            <div>
                                {membershipData.benefitsDetails && (
                                    <>
                                        <h1 className="text-center  font-weight-bold mt-3" style={{ fontSize: '40px', color: '#1d8296' }}>{membershipData.benefitsDetails.title}</h1>
                                    </>
                                )}

                                <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem', padding: '0% 10%' }}>
                                    {membershipData.benefitsDetails &&
                                        membershipData.benefitsDetails.benefitsContent.map((section, index) => (
                                            <div key={index} style={{
                                                display: 'flex',
                                                flexDirection: index % 2 === 0 ? 'row' : 'row-reverse',
                                                alignItems: section.title !== 'WorthPoint Subscription' ? 'center' : '',
                                                marginTop: '3rem',
                                                justifyContent: 'space-between'
                                                
                                            }} >
                                                <div style={{ flex: '0 0 auto', 
                                                marginTop: section.title == 'WorthPoint Subscription' ? '2rem' : '',
                                                textAlign: index % 2 === 0 ? 'left' : 'right' }}>
                                                    <img src={section.logo} alt="logo" style={{ width: '100px' }} />
                                                </div>

                                                <div style={{ flex: '1', margin: '0 1rem' }}>
                                                    <div className="font-weight-bold" style={{ color: section.titleColor, fontSize: '20px' }}>{section.title}</div>
                                                    {Array.isArray(section.description) ? (
                                                        section.description.map((desc, idx) => (
                                                            <div className="mt-3 fs-20" key={idx}>{this.boldTextChange(desc)}</div>
                                                        ))
                                                    ) : (
                                                        <div className="fs-20">{this.textColorChange(section.description)}</div>
                                                    )}

                                                    {section.descriptionPoints &&
                                                        section.descriptionPoints.map((point, idx) => (
                                                            <div key={idx} className="mt-3">
                                                                <ul>
                                                                    <li className="fs-20">{point}</li>
                                                                </ul>
                                                            </div>
                                                        ))}
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>

                                {!this.props.user.membershipCompleted ?
                                <div className='w-100 mt-5' style={{ justifyContent: 'center', alignItems: ' center', display: 'flex', }}>
                                    {bannerImage(this.props, 'Premier-BannerAd-PMMainOfferPage-Unlock3')}
                                </div> : ''}
                            </div>
                            <div className="p-3 mt-5">
                                <p className="footer-message">
                                    {membershipData?.footer && this.linkAddFn(membershipData?.footer[2]?.description)}
                                </p>
                            </div>
                        </div>
                    </div>}
                <div className="mt-3">
                    <Footer className={'remove-bottom'}{...this.props} />
                </div>
            </div>
        );
    }

}

export default connect(state => state)(VipMembership);
